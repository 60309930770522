import "./App.css";
import logo from "./Images/logo_transparent.png";

function App() {
  return (
    <div className="App">
      <img src={logo} className="logo" alt="ejon.es logo" />
    </div>
  );
}

export default App;
